import React  from "react";
import styled from "styled-components";



const AboutRap = styled.div`
.text-hidden {
    display: none;
}

@media (max-width: 767px)  {
    .image2 {
        height: 250px !important;
    }
    h2 {
        font-size: 40px !important;
    }
    .text-block {
        display: block !important;
    }
    .block-div {
        width: 99% !important;
    }

    .text-hidden {
        display: block !important;
    }
    .now-hide {
        display: none !important;
    }
    .div-space {
        margin-top: 20px;
    }
    .con-div {
        height: 220px !important;
    }
}

@media (max-width: 475px) {
    .team-img {
        height: 250px !important;
    }
}
@media (max-width: 426px) {
    .con-div {
        height: 270px !important;
    }
}
@media (max-width: 335px) {
    .con-div {
        height: 350px !important;
    }
}
`


const About = () => {

  

    return (
        <AboutRap>
        <div style={{ padding: "60px 10px" }} className="container">
            <div className="d-flex">
                <div className="col-7">
                    <div style={{ maxWidth: "350px", marginBottom: "50px" }}>
                        <h2 style={{ fontSize: "50px", marginBottom: "20px" }}>About Company</h2>
                        <p>F.O.Z ENGINEERING VENTURES is a sole proprietorship business established and own
                            by Engineer Opaleye Folarin Oluwatobi. It is located at 9B, Aquamarine Estate, Apo, Opposite Apo Mechanic, Abuja.</p>
                    </div>
                    <div className="mr-2 image1" style={{ height: "400px" }}>
                        <img
                            style={{
                                height: "100%", width: "100%", objectFit: "cover",
                                display: "block", objectPosition: "top", borderRadius: "10px"
                            }}
                            src="./images/image9.jpg"
                            alt='...'
                        />
                    </div>
                </div>
                <div>
                    <div className="mr-2 image2" style={{ height: "400px", }}>
                        <img
                            style={{
                                height: "100%", width: "100%", objectFit: "cover",
                                display: "block", objectPosition: "top", borderRadius: "10px"
                            }}
                            src="./images/image42.jpg"
                            alt='...'
                        />
                    </div>
                    <div style={{ marginTop: "30px" }}>
                        <p>
                            Though we have been working behind the scenes for more than 11 years, the company was fully registered 3 years ago.
                            I had worked with many organisations and firms in the past where I had acquired a lot of knowledge and experience
                            which moulded me into becoming an expert in what the company offers. We deal in construction of buildings
                            from nothing to something elegant and fit for royals. We also renovate old and dilapidated buildings to magnificent buildings.
                        </p>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "100px" }} className="d-flex justify-content-between text-block">
                <div style={{width: "48%"}} className=" block-div">
                    <h2 style={{ fontSize: "50px", marginBottom: "50px", maxWidth: "350px" }}>We Help Build Your Dream</h2>
                    <p><strong>Our Dream</strong> is to envision a future where our construction projects not only redefine architectural
                        landscapes but also serve as beacons of sustainability, innovation, and social responsibility.
                        We dream of creating spaces that inspire, empower,
                        and endure for generations to come, leaving a lasting legacy of excellence in construction and positive impact on the world.
                    </p>
                    <p><strong>Our Mission </strong> deliver excellence in construction solutions through innovative design, superior craftsmanship,
                        and unwavering commitment to client satisfaction.
                        We strive to enhance the built environment while prioritizing sustainability, safety, and social responsibility.
                    </p>
                    <p><strong>Our Vission </strong>be a leading force in the construction industry, recognized for our cutting-edge expertise in cladding, thermal insulation, roofing, and curtain walls.
                        We envision a future where our projects inspire, endure, and contribute positively to communities and the environment.
                    </p>
                </div>
                <div style={{width: "48%"}}  className=" block-div">
                    <div className="mr-2" style={{ height: "300px", }}>
                        <img
                            style={{
                                height: "100%", width: "100%", objectFit: "cover",
                                display: "block", objectPosition: "top", borderRadius: "10px"
                            }}
                            src="./images/image26.jpg"
                            alt='...'
                        />
                    </div>
                    <div style={{ height: "200px", marginTop: "30px" }} className="d-flex justify-content-between">
                        <div style={{ width: "48%" }}>
                            <div style={{ background: "#f5f5f5", padding: "5px 15px", borderRadius: "10px" }}>
                                <h2>11+</h2>
                                <p>Years Experience</p>
                            </div>
                            <div style={{ background: "#f5f5f5", padding: "5px 15px", borderRadius: "10px", marginTop: "10px" }}>
                                <h2>23</h2>
                                <p>Project Challenge</p>
                            </div>
                        </div>
                        <div style={{ width: "48%" }}>
                            <div style={{ background: "#f5f5f5", padding: "5px 15px", borderRadius: "10px" }}>
                                <h2>600+</h2>
                                <p>Postive Reviews</p>
                            </div>
                            <div style={{ background: "#f5f5f5", padding: "5px 15px", borderRadius: "10px", marginTop: "10px" }}>
                                <h2>1000+</h2>
                                <p>Trusted Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "100px" }} className="d-flex justify-content-between text-block">
                <div style={{width: "48%"}} className="block-div">
                <h2 className="text-hidden" style={{ fontSize: "50px", marginBottom: "50px", maxWidth: "350px" }}>The Owner</h2>
                    <div className="mr-2" style={{ height: "600px", }}>
                        <img
                            style={{
                                height: "100%", width: "100%", objectFit: "cover",
                                display: "block", objectPosition: "top", borderRadius: "10px"
                            }}
                            src="./images/fola.jpg"
                            alt='...'
                        />
                    </div>
                </div>
                <div style={{width: "48%"}} className="block-div div-space">
                    <h2 className="now-hide" style={{ fontSize: "50px", marginBottom: "50px", maxWidth: "350px" }}>The Owner</h2>
                    <p>
                        Folarin is a graduate of Mechanical Engineering and has been in the field of Engineering for many years.
                        He has worked with quite a number of Engineering firms as Site/Field Engineer, Sales Executive, and Project
                        Manager where he has acquired a lot of experience relating to Aluminum industry, Engineering field, construction, and building.
                    </p>
                    <p>
                        His vast experience has made him to excel in delivering and executing projects for banks, government buildings, private buildings,
                        schools, restaurants, and hotels. As a team leader, he is an influential communicator, speaking the language of both the best
                        analytic minds and the most creative strategist. He has shaped a credible executive presence and is credited
                        with playing an instrumental role in the aluminum industry as an innovative leader in the Construction and Aluminum Industry.
                    </p>
                    <p>
                        His interest is to excel in Construction, Aluminum Industry and Engineering field.
                        He enjoys spending quality time with family and friends, reading and making research, and watching sports.
                    </p>
                </div>
            </div>
            <div style={{ marginTop: "100px" }}>
                <div>
                    <h2 style={{ fontSize: "30px", marginBottom: "50px", maxWidth: "400px" }}>Dedicated Teams. <br /> For Your Dedicated Dreams.</h2>
                </div>
                <div>
                    <div className="mr-2" style={{ height: "400px", }}>
                        <img
                            style={{
                                height: "100%", width: "100%", objectFit: "cover",
                                display: "block", objectPosition: "top",
                            }}
                            src="./images/team.jpg"
                            alt='...'
                        />

                    </div>
                    <div className="col-8 con-div" style={{
                        height: "170px", backgroundColor: "#8d7b7b", 
                        left: "40px", marginTop: "-80px", padding: "20px", color:"white"
                    }}>
                        <h4>Why Do These</h4>
                        <p>The founder also feel the burden of designing and constructing his very first building. 
                            This frustration manifested into products born to help others build their dream houses.
                        </p>
                    </div>
                </div>
                <div style={{marginTop: "50px"}} className="d-flex justify-content-between">
                    <div className="mr-2 team-img" style={{ height: "300px", width: "32%" }}>
                        <img
                            style={{
                                height: "80%", width: "100%", objectFit: "cover",
                                display: "block", objectPosition: "top", borderRadius: "10px", 
                            }}
                            src="./images/fola.jpg"
                            alt='...'
                        />
                        <h5 style={{ marginTop: "15px" }}>Company's C.E.O</h5>
                    </div>
                    <div className="mr-2 team-img" style={{ height: "300px", width: "32%" }}>
                        <img
                            style={{
                                height: "80%", width: "100%", objectFit: "cover",
                                display: "block", objectPosition: "top", borderRadius: "10px"
                            }}
                            src="./images/david.jpg"
                            alt='...'
                        />
                        <h5 style={{ marginTop: "15px" }}>Dav-Tech's C.E.O</h5>
                    </div>
                    <div className="mr-2 team-img" style={{ height: "300px", width: "32%" }}>
                        <img
                            style={{
                                height: "80%", width: "100%", objectFit: "cover",
                                display: "block", objectPosition: "top", borderRadius: "10px"
                            }}
                            src="./images/bolu.jpg"
                            alt='...'
                        />
                        <h5 style={{ marginTop: "15px" }}>Software Engineer</h5>
                    </div>
                </div>
               
            </div>
        </div>
        </AboutRap>
    )
}

export default About