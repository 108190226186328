import React from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

const SectionRap = styled.div`
padding: 40px;
.div-box {
    padding: 15px; 
    margin: 10px;
    background: white;
    color: black;
    font-size: 20px;
    font-weight: bold;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 48%;
    display: flex;
    text-align: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
}
`
const AllSection = () => {
    const navigate = useNavigate()

    const handleCladding = () => {
        navigate("/admin/cladding")
    }
    const handleThermal = () => {
        navigate("/admin/thermal")
    }
    
   const handleRoofing = () => {
    navigate("/admin/roofing")
   }
    const handleCurtain = () => {
        navigate("/admin/curtain")
    }
    return (
        <SectionRap>
        <div>
             <div onClick={handleCladding} className="div-box">
                    <p>Update Cladding</p>
                </div>
            <div onClick={handleThermal} className="div-box">
                    <p>Update Thermal</p>
                </div>
                
               
                <div onClick={handleRoofing} className="div-box">
                    <p>Update Roofing</p>
                </div>
                <div onClick={handleCurtain} className="div-box">
                    <p>Update Curtain</p>
                </div>
        </div>
        </SectionRap>
    )
}
export default AllSection