import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";

const LatestRap = styled.div`
padding:40px;

  input, textarea {
    width: 50%;
    margin: 20px 0px;
  }

  .btn-submit {
    background: blue !important;
    color: white;
    font-weight: bold;
  }
  @media (max-width: 700px) {
    input, textarea {
        width: 100%;
       
      }
}
`

const Latest = () => {
    const [title, setTitle] = useState("");
    const [paragraph, setParagraph] = useState("");
    const [latestImage, setLatestImage] = useState(null);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleParagraphChange = (event) => {
        setParagraph(event.target.value);
    };

    const handleImageChange = (event) => {
        setLatestImage(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("title", title);
        formData.append("paragraph", paragraph);
        if (latestImage) {
            formData.append("latestImage", latestImage);
        }

        try {
            const response = await axios.post("https://foz-server.onrender.com/api/latest", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("Response:", response.data);
        } catch (error) {
            console.error("Error uploading data:", error);
        }
    };

    return (
        <LatestRap>
        <div>
            <h2>Update Latest News</h2>
            <form style={{marginTop: "60px"}} onSubmit={handleSubmit}>
                <div>
                    <h4>News Headline</h4>
                    <input
                        type="text"
                        className="form-control"
                        value={title}
                        onChange={handleTitleChange}
                    />
                </div>
                <div>
                    <h4>News Paragraph</h4>
                    <textarea
                        type="text"
                        className="form-control"
                        value={paragraph}
                        onChange={handleParagraphChange}
                    />
                </div>
                <div>
                    <h4>News Image</h4>
                    <input
                        type="file"
                        className="form-control"
                        onChange={handleImageChange}
                    />
                </div>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <button className="btn btn-submit" type="submit">Submit</button>
                </div>
            </form>
        </div>
        </LatestRap>
    );
};

export default Latest;
