
import './App.css';

import { BrowserRouter, } from "react-router-dom"
import Routes from './Routes';
import { AppProvider } from './Component/Context';
import Loader from './Layouts/Custom/overlayProgress';
import { useEffect, useState } from 'react';

function App() {
  


  return (
   

          <AppProvider>
            <div className="">
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </div>
          </AppProvider>
      
  );
}

export default App;
