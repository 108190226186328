import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Marquee from "react-fast-marquee";
import Loader from "../Layouts/Custom/overlayProgress";


const HomeRap = styled.div`
    .landing {

        background: linear-gradient(to right, #212526 50%, #2e3233 50%);
        color: white;
        height: 90vh;
        overflow:hidden !important;
    }
.image{
    width:100%;
    height:90vh;
    display: block;
    overflow:hidden;
    position:absolute;
    img{
        width:100%;
        height:100%;  
    }
}

   .test-div{
    z-index:2 !important;
    max-width: 550px;
    margin-top: 20px;
   }
     .test-div p {
        max-width: 300px !important;
     }

     .button {
        border-radius: 30px;
        background-color: #212526;
        border: 1px solid #a18e2a;
        color: white;
        padding: 5px 20px;
        margin-top: 70px;
        cursor: pointer;
     }
     .design-cont h2{
        max-width: 200px;
        line-height: 1.4;
     } 
     .design-cont {
        max-width: 350px;
       
     } 
     .consult {

        background-image: url(../images/consult.jpg);
        background-size: cover;
        background-position: center;
        color: white;
        padding: 30px;
       
      }
      .parent{
        position:relative;
        width:auto;
        height: 400px;
        overflow:hidden !important;
      }
      .dark-bg{
        position:absolute;
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); /* Adjust opacity and colors as needed */
        height:400px;
        width: 100%;
        color:white;
        padding: 30px;
      }
      
      .dark-bg p {
        max-width: 400px;
        font-size: 18px;
      }
      .dark-bg h3 {
        max-width: 400px;
        color: white;
      }
      .letter {
        background-image: url(../images/letter.jpg);
        margin-top: 40px;
        height:400px;
      }
      .design {
        background-image: url(../images/design.jpg);
        margin-top: 40px;
      }
      .construct {
        background-image: url(../images/const.jpg);
        margin-top: 40px;
      }
      .secButton, .secButton.active {
        background-color: white;
        padding: 6px;
        border-radius: 5px;
        border-style: none;
        cursor: pointer;
        font-weight: bold;
        font-size: 18px
      }
      .secButton:hover, .secButton.active {
        background: blue;
        color: white;
        border-style: none;
      }
      .service-image {
        
      }

      @media (max-width: 991px) {
        .image-hide {
            display: none;
        }
        .image1, .image2, .image3 {
            width: 100% !important;
        }
       .div-width {
        width: 300px !important;
       }
      }
      @media (max-width: 767px) {
        .div-width {
            width: 230px !important;
           }
        .div-width > img {
            height: 65% !important;
        }
      }
      @media (max-width: 510px) {
        .div-width {
            width: 210px !important;
           }
        .secButton,.secButton.active {
            
        }
      }
      @media (max-width: 470px) {
        .div-width {
            width: 400px !important;
           }
           .secButton, .secButton.active {
           
            padding: 4px;
        }
      }
      @media (max-width: 400px) {
        .secButton, .secButton.active {
           font-size: 16px;
            padding: 2px;
        }
      }

      @media (max-width: 700px) {
        .image1, .image3 {
            height: 200px !important;
        }
        .image2 {
            height: 200px !important;
        }

        
        
      }
`


const Home = () => {

    const [loading, setLoading] = useState(true);


    useEffect(() => {
      const loadingTime = setTimeout(() => {
        setLoading(false)
      }, 3000)
  
      return () => {
        clearTimeout(loadingTime)
      };
    }, []);

    const [message, setMessage] = useState("We utilize top-quality, durability and longevity materials. We employ advanced manufacturing techniques to ensure precise measurements and seamless assembly. We also subject materials and finished products to rigorous quality control tests to meet industry standards.")
    const [activeButton, setActiveButton] = useState(null);

    const handleClickMaterial = () => {
        setMessage("We utilize top-quality, durability and longevity materials. We employ advanced manufacturing techniques to ensure precise measurements and seamless assembly. We also subject materials and finished products to rigorous quality control tests to meet industry standards.")
        setActiveButton('material');
    }
    const handleClickPerformance = () => {
        setMessage("Incorporating cutting-edge thermal insulation materials to enhance energy efficiency and reduce heat loss. Providing weather-resistant cladding systems engineered to withstand extreme conditions while maintaining structural integrity. Satisfaction of our clients are our major goal.")
        setActiveButton('performance');
    }
    const handleClickReliablity = () => {
        setMessage("Demonstrating a history of successful projects and satisfied clients, showcasing our reliability and commitment to excellence. Prioritizing clear and open communication with clients throughout the project lifecycle, ensuring their needs and expectations are met.")
        setActiveButton('reliability');
    }

    return (
        <>
        {loading ? <Loader /> : (

        <HomeRap>
            <div className="">
                <div className="d-flex flex-column landing">
                    <div className="p-5 test-div">
                        <h1 style={{ color: "white" }}>Building Tomorrow, Shielding Today</h1>
                        <div style={{ marginTop: "30px", color: "#e9e9e9" }}>
                            <p >
                                Your Trusted Source for Roofing, Cladding, Aluminium Windows and Doors and Insulation Solution.
                            </p>
                        </div>
                        <a href="https://wa.me/+2348075538820?text=Hello"><button className="button" type="button"> Get in Touch </button></a>
                    </div>
                    <div className="image">
                        <img src="./images/final_foz.png" />
                    </div>
                </div>
                <div style={{ backgroundColor: "#f5f5f5", padding: "50px 0px" }}>
                    <div className="container" >
                        <div  >

                            <h2 style={{ maxWidth: "200px" }}>We Are Construction Company</h2>
                            <p style={{ marginTop: "20px" }}>Our works speak for us</p>
                        </div>
                        <div className="d-flex ">
                            <div className="mr-2 image1" style={{ height: "300px", width: "25%", marginTop: "50px" }}>
                                <img
                                    style={{
                                        height: "100%", width: "100%", objectFit: "cover",
                                        display: "block", objectPosition: "top",
                                    }}
                                    src="./images/image9.jpg"
                                    alt='...'
                                />
                            </div>
                            <div className="mr-2 image2" style={{ height: "400px", width: "25%", marginTop: "100px" }}>
                                <img
                                    style={{
                                        height: "100%", width: "100%", objectFit: "cover",
                                        display: "block", objectPosition: "top",
                                    }}
                                    src="./images/image52.jpg"
                                    alt='...'
                                />
                            </div>
                            <div className="mr-2 image3" style={{ height: "300px", width: "25%", marginTop: "80px" }}>
                                <img
                                    style={{
                                        height: "100%", width: "100%", objectFit: "cover",
                                        display: "block", objectPosition: "top",
                                    }}
                                    src="./images/image31.jpg"
                                    alt='...'
                                />
                            </div>
                            <div className="mr-2 image-hide" style={{ height: "400px", width: "25%", marginTop: "30px" }}>
                                <img
                                    style={{
                                        height: "100%", width: "100%", objectFit: "cover",
                                        display: "block", objectPosition: "top",
                                    }}
                                    src="./images/image46.jpg"
                                    alt='...'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "50px", padding: "50px 0px" }}>

                    <div className="container" >
                        <h2>What We Do</h2>
                        <div style={{ marginTop: '30px' }}>
                            <div className="d-flex  flex-row flex-wrap  service-image">
                                <div className="my-2 div-width" style={{
                                    marginRight: "10px",
                                    width: "350px", height: "430px", borderRadius: "10px", background: "#fbfbfb",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    // Horizontally center the content
                                    alignItems: "center",
                                    padding: "15px",

                                }}>
                                    <img
                                        style={{ width: "100%", height: "80%", objectFit: "cover", borderRadius: "10px", }}
                                        className="title card-img"
                                        src="./images/image25.jpg"
                                        alt="house"
                                    />
                                    <div style={{ marginTop: "10px" }}>
                                        <h4 className="m-0">External Cladding</h4>
                                        <p className="mt-0">To offer the utmost protection against weather elements</p>
                                    </div>
                                </div>
                                <div className="my-2 div-width" style={{
                                    marginRight: "10px",
                                    width: "350px", height: "430px", borderRadius: "10px", background: "#fbfbfb",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    // Horizontally center the content
                                    alignItems: "center",
                                    padding: "10px",

                                }}>
                                    <img
                                        style={{ width: "100%", height: "80%", objectFit: "cover", borderRadius: "10px", }}
                                        className="title card-img"
                                        src="./images/image55.jpg"
                                        alt="house"
                                    />
                                    <div style={{ marginTop: "10px" }}>
                                        <h4 className="m-0">Internal Cladding</h4>
                                        <p className="mt-0">Transform your indoor spaces with our exquisite internal cladding selections</p>
                                    </div>
                                </div>
                                <div className="my-2 div-width" style={{
                                    marginRight: "10px",
                                    width: "350px", height: "430px", borderRadius: "10px", background: "#fbfbfb",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    // Horizontally center the content
                                    alignItems: "center",
                                    padding: "10px",

                                }}>
                                    <img
                                        style={{ width: "100%", height: "80%", objectFit: "cover", borderRadius: "10px", }}
                                        className="title card-img"
                                        src="./images/alu.jpg"
                                        alt="house"
                                    />
                                    <div style={{ marginTop: "10px" }}>
                                        <h4 className="m-0">Thermal Insullation</h4>
                                        <p className="mt-0">We offer a wide range of thermal and acoustic insulation</p>
                                    </div>
                                </div>
                                <div className="my-2 div-width" style={{
                                    marginRight: "10px",
                                    width: "350px", height: "430px", borderRadius: "10px", background: "#fbfbfb",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    // Horizontally center the content
                                    alignItems: "center",
                                    padding: "10px",

                                }}>
                                    <img
                                        style={{ width: "100%", height: "80%", objectFit: "cover", borderRadius: "10px", }}
                                        className="title card-img"
                                        src="./images/roof1.jpg"
                                        alt="house"
                                    />
                                    <div style={{ marginTop: "10px" }}>
                                        <h4 className="m-0">Roofing</h4>
                                        <p className="mt-0">From roofs to dreams, we've got you covered</p>
                                    </div>
                                </div>
                                <div className="my-2 div-width" style={{
                                    marginRight: "10px",
                                    width: "350px", height: "430px", borderRadius: "10px", background: "#fbfbfb",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    // Horizontally center the content
                                    alignItems: "center",
                                    padding: "10px",

                                }}>
                                    <img
                                        style={{ width: "100%", height: "80%", objectFit: "cover", borderRadius: "10px", }}
                                        className="title card-img"
                                        src="./images/image20.jpg"
                                        alt="house"
                                    />
                                    <div style={{ marginTop: "10px" }}>
                                        <h4 className="m-0">Aluminum Doors & Windows</h4>
                                        <p className="mt-0">Elevate your space with style and security</p>
                                    </div>
                                </div>
                                <div className="my-2 div-width" style={{
                                    marginRight: "10px",
                                    width: "350px", height: "430px", borderRadius: "10px", background: "#fbfbfb",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    // Horizontally center the content
                                    alignItems: "center",
                                    padding: "10px",

                                }}>
                                    <img
                                        style={{ width: "100%", height: "80%", objectFit: "cover", borderRadius: "10px", }}
                                        className="title card-img"
                                        src="./images/image37.jpg"
                                        alt="house"
                                    />
                                    <div style={{ marginTop: "10px" }}>
                                        <h4 className="m-0">Curtain walls</h4>
                                        <p className="mt-0">Elevate your view with precision-crafted curtain walls</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div style={{ background: "#f5f5f5", padding: "80px 0px" }}>
                    <div style={{ alignItems: "center" }} className="d-flex  container flex-row flex-wrap" >
                        <div className="col-lg-7">
                            <div className="mr-2" style={{ height: "400px", }}>
                                <img
                                    style={{
                                        height: "100%", width: "100%", objectFit: "cover",
                                        display: "block", objectPosition: "top", borderRadius: "10px"
                                    }}
                                    src="./images/image31.jpg"
                                    alt='...'
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-around col-lg-5 my-5" >
                            <h2>Only Quality For Clients</h2>
                            <div style={{ marginTop: "20px" }} className="d-flex justify-content-between">
                                <button onClick={handleClickMaterial} className={`secButton ${activeButton === 'material' ? 'active' : ''}`} type="button" > Materials</button>
                                <button onClick={handleClickPerformance} className={`secButton ${activeButton === 'performance' ? 'active' : ''}`} type="button" >Performance </button>
                                <button onClick={handleClickReliablity} className={`secButton ${activeButton === 'reliability' ? 'active' : ''}`} type="button" >Reliability </button>
                            </div>
                            <p style={{ fontSize: "17px", marginTop: "20px" }}>{message}</p>
                            <a href="/services"><button style={{ width: "200px" }} className="button" type="button"> Learn More </button></a>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "100px" }} className="container  d-flex flex-column justify-content-around">
                    <div className="design-cont">

                        <h2>How We Design</h2>
                        <p style={{ margin: "20px 0px" }}>We follow a trend and tested process to ensure that our clients are completely satisfied.</p>
                    </div>
                    <div className="d-flex flex-column parent">

                        <div className="consult w-100 h-100">
                        </div>
                        <div className="dark-bg d-flex flex-column justify-content-around"><p>Step 1</p>
                            <h3>Consultation and on-Site Review</h3>
                            <p >We start by conducting consultation with our clients to understand their vission and requirements.
                                We then conduct and on-site review to access the space and identify any challenge that may need to be addressed.
                            </p>
                            <a href="https://wa.me/+2348075538820?text=Hello"><button style={{ width: "200px", marginTop: "1px" }} className="button" type="button"> Get in Touch </button> </a>
                        </div>

                    </div>


                    <div className="d-flex flex-column parent" >

                        <div className="letter consult w-100 h-100">
                        </div>
                        <div style={{ marginTop: "40px" }} className="dark-bg d-flex flex-column justify-content-around">
                            <div style={{ marginTop: "-40px" }}>
                                <p >Step 2</p>
                                <h3 >Letter of Agreement</h3>
                                <p >We start by conducting consultation with our clients to understand their vission atond requirements.
                                    We then conduct and on-site review to access the space and identify any challenge that may need to be addressed.
                                </p>
                                 <a href="https://wa.me/+2348075538820?text=Hello"><button style={{ width: "200px", marginTop: "1px" }} className="button" type="button"> Get in Touch </button> </a>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column parent" >
                        <div className="design consult w-100 h-100">
                        </div>

                        <div style={{ marginTop: "40px" }} className="dark-bg d-flex flex-column justify-content-around">
                            <div style={{ marginTop: "-40px" }}>
                                <p>Step 3</p>
                                <h3>Design and Development</h3>
                                <p style={{ marginTop: '4px' }}>
                                    Our team of designers create a design that is tailored to the client's need and preferences. We provide 3D models to help
                                    client visualize the end result.
                                </p>
                                 <a href="https://wa.me/+2348075538820?text=Hello"><button style={{ width: "200px", marginTop: "1px" }} className="button" type="button"> Get in Touch </button> </a>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column parent">
                        <div className="construct consult w-100 h-100">
                        </div>

                        <div style={{ marginTop: "40px" }} className="dark-bg d-flex flex-column justify-content-around">
                            <div style={{ marginTop: "-35px" }}>
                                <p>Step 4</p>
                                <h3>Construction and Installation</h3>
                                <p style={{ marginTop: '10px' }}>
                                    Once the design is approved, we move on to construction and installation. We ensure that the project is completed within the
                                    allocated budget and deadline ensuring that our client is completely satisfied.
                                </p>
                                <a href="https://wa.me/+2348075538820?text=Hello"><button style={{ width: "200px", marginTop: "-15px" }} className="button" type="button"> Get in Touch </button> </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "100px" }}>
                    <div className="container ">

                        <h3 style={{ maxWidth: "200px" }}>Some Selected project</h3>
                        <p style={{ margin: "20px 0px" }}>Check out some of our works</p>
                    </div>
                    <div>
                        <Marquee pauseOnHover={true} speed={40} direction="left">
                            <div style={{
                                marginRight: "10px",
                                width: "200px", height: "200px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image13.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "200px", height: "200px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image16.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "200px", height: "200px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image19.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "200px", height: "200px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image25.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "200px", height: "200px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image21.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "200px", height: "200px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image26.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "200px", height: "200px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image27.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "200px", height: "200px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image28.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "200px", height: "200px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image29.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "200px", height: "200px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image30.jpg"
                                    alt="house"
                                />
                            </div>
                        </Marquee>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <Marquee pauseOnHover={true} speed={40} direction="left">
                            <div style={{
                                marginRight: "10px",
                                width: "150px", height: "100px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image31.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "150px", height: "100px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image32.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "150px", height: "100px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image40.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "150px", height: "100px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image41.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "150px", height: "100px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image42.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "150px", height: "100px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image43.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "150px", height: "100px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image44.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "150px", height: "100px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image53.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "150px", height: "100px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/image59.jpg"
                                    alt="house"
                                />
                            </div>
                            <div style={{
                                marginRight: "10px",
                                width: "150px", height: "100px", borderRadius: "10px", background: "white",
                                display: "flex",
                                justifyContent: "center", // Horizontally center the content
                                alignItems: "center",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)"
                            }}>
                                <img
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px", }}
                                    className="title card-img"
                                    src="./images/roof1.jpg"
                                    alt="house"
                                />
                            </div>
                        </Marquee>
                    </div>

                </div>
            </div>

        </HomeRap>
        )}
        </>
    )
}

export default Home