import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import AdminLoader from '../../Layouts/Custom/adminProgress';

const DisplayRap = styled.div`
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    padding: 10px;
  }

//   .image-item img {
//     width: 100%;
//     object-fit: cover;
   
//   }

  .no-images {
    text-align: center;
    font-size: 18px;
    color: #666;
    padding: 20px;
  }
`;

const UploadedImage = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true)

    const fetchImages = async () => {
        try {
            const response = await axios.get('https://foz-server.onrender.com/api/get-images');
            setImages(response.data);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    useEffect(() => {

        fetchImages();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://foz-server.onrender.com/api/delete-images/${id}`);
            fetchImages(); // Refresh the list after deletion
            console.log("deleted");
        } catch (error) {
            console.error('Error deleting images:', error);
        }
    };

    return (
        <>
            {loading ? <AdminLoader /> : (

                <DisplayRap>
                    <div style={{ padding: "40px" }}>
                        <h2>Image Uploaded to Gallery</h2>
                        {images.length > 0 ? (
                            <div className="my-5 image-grid">
                                {images.map((image) => (
                                    <div className="image-item my-5" key={image._id} >
                                        <div style={{ height: "250px", width: "250px" }}>

                                            <img
                                                style={{
                                                    height: "100%", width: "100%", objectFit: "cover",
                                                    display: "block", objectPosition: "top",
                                                }}
                                                src={image.url} alt="Uploaded" />
                                        </div>
                                        <button onClick={() => handleDelete(image._id)} style={{ marginTop: "10px", backgroundColor: "red", color: "white", border: "none", padding: "5px 10px", cursor: "pointer" }}>
                                            Delete
                                        </button>
                                    </div>

                                ))}
                            </div>
                        ) : (
                            <div style={{ alignItems: "center", height: "100vh" }} className="d-flex flex-column justify-content-center">

                                <h1 style={{ alignSelf: "center" }} >No Image Uploaded</h1>

                            </div>
                        )}
                    </div>
                </DisplayRap>
            )}
        </>
    );
};

export default UploadedImage;
