import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";


const ServiveRap = styled.div`
background-color: #1e1a1a;
padding: 40px 10px;
color: white;
margin-bottom: -60px;


.button {
    border-radius: 30px;
    background-color: #212526;
    border: 1px solid #a18e2a;
    color: white;
    padding: 5px 20px;
    margin-top: 30px;
 }

 @media (max-width: 767px) {
    .div-block {
        flex-direction:  column-reverse !important; 
    }
    .div-block-new {
        flex-direction:  column !important; 
    }
    .div-new-width {
        width: 98% !important
    }
    .div-text {
        margin-top: 20px;
    }
 }
 
`

const Service = () => {


    const [mainImage, setMainImage] = useState('./images/image19.jpg');
    const [secondMainImage, setSecondMainImage] = useState('./images/image39.jpg')
    const [termalMain, setTermalMain] = useState('./images/image47.jpg')
    const [roofMain, setRoofMain] = useState('./images/roof1.jpg')
    const [curtainMain, setCurtainMain] = useState('./images/image31.jpg')
    const [imageOne, setImageOne] = useState('./images/image32.jpg');
    const [imageTwo, setImageTwo] = useState('./images/image25.jpg');
    const [imageThree, setImageThree] = useState('./images/image21.jpg');
    const [imageFour, setImageFour] = useState('./images/image2.jpg');
    const [imageFive, setImageFive] = useState('./images/image59.jpg');
    const [imageSix, setImageSix] = useState('./images/image51.jpg');
    const [termalOne, SetTermalOne] = useState('./images/image8.jpg');
    const [termalTwo, SetTermalTwo] = useState('./images/rock.jpg');
    const [termalThree, SetTermalThree] = useState('./images/image24.jpg');
    const [roofOne, setRoofOne] = useState('./images/roof2.jpg');
    const [roofTwo, setRoofTwo] = useState('./images/roof3.jpg');
    const [roofThree, setRoofThree] = useState('./images/roof4.jpg');
    const [curtainOne, setCurtainOne] = useState('./images/image15.jpg');
    const [curtainTwo, setCurtainTwo] = useState('./images/image37.jpg');
    const [curtainThree, setCurtainThree] = useState('./images/image22.jpg');

    const imageData = [
        {
            imageUrl: imageOne,
            id: "1",
        },
        {
            imageUrl: imageTwo,
            id: "2",
        },
        {
            imageUrl: imageThree,
            id: "3",
        },
       
    ]
    const secondImageData = [
        {
            imageUrl: imageFour,
            id: "4",
        },
        {
            imageUrl: imageFive,
            id: "5",
        },
        {
            imageUrl: imageSix,
            id: "6",
        },
    ]
    const thermalData = [
        {
            imageUrl: termalOne,
            id: "7",
        },
        {
            imageUrl: termalTwo,
            id: "8",
        },
        {
            imageUrl: termalThree,
            id: "9",
        },
    ]
    const roofData = [
        {
            imageUrl: roofOne,
            id: "10",
        },
        {
            imageUrl: roofTwo,
            id: "11",
        },
        {
            imageUrl: roofThree,
            id: "12",
        },
    ]
    const curtainData = [
        {
            imageUrl: curtainOne,
            id: "13",
        },
        {
            imageUrl: curtainTwo,
            id: "14",
        },
        {
            imageUrl: curtainThree,
            id: "15",
        },
    ]
   

    return (
        <ServiveRap>
            <div className="container">
                <div>
                    <h1 style={{maxWidth: "100px", color: "#e5e0e0", marginBottom:'70px'}}>OUR SERVICES</h1>
                </div>
                <div style={{ alignItems: "center" }} className="d-flex flex-row justify-content-between div-block flex-wrap">
                    <div className="div-new-width div-text" style={{ width: "48%" }}>
                        <h2>External Cladding (Superior Protection with Style)</h2>
                        <p>

                            Our external cladding range is designed to offer the utmost protection against weather elements while providing a modern and sleek look to the
                            building facade. From weather-resistant vinyl to luxurious stone and eco-friendly wood composites, our versatile selection caters to all tastes
                            and architectural styles. Ensuring longevity,
                            enhanced insulation, and reduced maintenance, FOZ Engineering Ventures’s external cladding products stand as a testament to quality and innovation.
                        </p>
                        <p>
                        F.O.Z Engineering Ventures offers a variety of services from design and planning to construction of the building. Our aim is to make the whole
                         procedure of Alucopanel and associated works run smoothly from start to finish with the perceived perception becoming a reality.
                        </p>
                        <button className="button" type="button"> Learn More </button>
                    </div>
                    <div className="div-new-width" style={{ width: "48%" }}>
                        <div>
                            <div className="mr-2" style={{ height: "400px", width: "100%" }}>
                                <img
                                    style={{
                                        height: "100%", width: "100%", objectFit: "cover",
                                        display: "block", objectPosition: "top", borderRadius: "10px"
                                    }}
                                    src={mainImage}
                                    alt='...'
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-4">
                            {
                                imageData.map(image => (
                                    <div className="mr-2" style={{ height: "100px", width: "30%" }}>
                                        <img
                                            style={{
                                                height: "100%", width: "100%", objectFit: "cover",
                                                display: "block", objectPosition: "top", borderRadius: "10px"
                                            }}
                                            src={image.imageUrl}
                                            alt='...'
                                            onClick={() => {
                                                setMainImage(image.imageUrl);
                                                if (image.id === "1") {
                                                    setImageOne(mainImage)
                                                }
                                                else if (image.id === "2") {
                                                    setImageTwo(mainImage)
                                                }
                                                else if (image.id === "3") {
                                                    setImageThree(mainImage)
                                                }
                                            }}

                                        />
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
                <div style={{ alignItems: "center", marginTop: "100px" }} className="d-flex flex-row justify-content-around div-block-new">
                    <div className="div-new-width div-text" style={{ width: "48%" }}>
                        <div className="mr-2" style={{ height: "500px", width: "100%" }}>
                            <img
                                style={{
                                    height: "100%", width: "100%", objectFit: "cover",
                                    display: "block", objectPosition: "top", borderRadius: "10px"
                                }}
                                src={secondMainImage}
                                alt='...'
                            />
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-4 ">
                            {
                                secondImageData.map(image => (
                                    <div className="mr-2" style={{ height: "100px", width: "30%" }}>
                                        <img
                                            style={{
                                                height: "100%", width: "100%", objectFit: "cover",
                                                display: "block", objectPosition: "top", borderRadius: "10px"
                                            }}
                                            src={image.imageUrl}
                                            alt='...'
                                            onClick={() => {
                                                setSecondMainImage(image.imageUrl);
                                                if (image.id === "4") {
                                                    setImageFour(secondMainImage)
                                                }
                                                else if (image.id === "5") {
                                                    setImageFive(secondMainImage)
                                                }
                                                else if (image.id === "6") {
                                                    setImageSix(secondMainImage)
                                                }
                                            }}

                                        />
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                    <div className="div-new-width div-text" style={{ width: "48%" }}>
                        <div>
                            <h2>Internal Cladding (Elegance Meets Functionality) </h2>
                            <p>

                                Transform your indoor spaces with our exquisite internal cladding selections. Our products bring elegance, warmth, and an extra layer of
                                soundproofing to any room. Available in an array
                                of materials, textures, and colors, our interior cladding solutions present endless design possibilities to create unique and inviting environments.
                            </p>
                            <p>
                            Our technical skills assure you that each job is completed to an exceptional standard. Our skill and services are 
                            second to none and our customer rating is high.
                            </p>
                            <button className="button" type="button"> Learn More </button>
                        </div>
                        
                    </div>
                </div>
                <div style={{ alignItems: "center", marginTop: "100px" }} className="d-flex flex-row justify-content-around div-block flex-wrap">
                    <div className="div-new-width div-text" style={{ width: "48%" }}>
                    <h2>Thermal & Acoustic Insulation</h2>
                        <p className="m-4">At FOZ Engineering Ventures, we offer a wide range of thermal and acoustic insulation for industrial, commercial and residential application.
                    We specialize in providing state-of-the-art thermal and acoustic insulation solutions tailored to meet the needs of modern construction and renovation
                    projects. Our solutions are designed to meet the most demanding requirements in terms of energy efficiency, fire safety, noice reduction and
                    other key performance indicators. Our products are made from high-quality materials that are safe, sustainable and long-lasting.
                </p>
                <button className="button" type="button"> Learn More </button>
                    </div>
                    <div className="div-new-width" style={{ width: "48%" }}>
                    
                        <div>
                            <div className="mr-2" style={{ height: "400px", width: "100%" }}>
                                <img
                                    style={{
                                        height: "100%", width: "100%", objectFit: "cover",
                                        display: "block", objectPosition: "top", borderRadius: "10px"
                                    }}
                                    src={termalMain}
                                    alt='...'
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-4">
                            {
                                thermalData.map(image => (
                                    <div className="mr-2" style={{ height: "100px", width: "30%" }}>
                                        <img
                                            style={{
                                                height: "100%", width: "100%", objectFit: "cover",
                                                display: "block", objectPosition: "top", borderRadius: "10px"
                                            }}
                                            src={image.imageUrl}
                                            alt='...'
                                            onClick={() => {
                                                setTermalMain(image.imageUrl);
                                                if (image.id === "7") {
                                                    SetTermalOne(termalMain)
                                                }
                                                else if (image.id === "8") {
                                                    SetTermalTwo(termalMain)
                                                }
                                                else if (image.id === "9") {
                                                    SetTermalThree(termalMain)
                                                }
                                            }}

                                        />
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
                <div style={{ alignItems: "center", marginTop: "100px" }} className="d-flex flex-row justify-content-around div-block-new">
                    <div className="div-new-width div-text" style={{ width: "48%" }}>
                        <div className="mr-2" style={{ height: "500px", width: "100%" }}>
                            <img
                                style={{
                                    height: "100%", width: "100%", objectFit: "cover",
                                    display: "block", objectPosition: "top", borderRadius: "10px"
                                }}
                                src={roofMain}
                                alt='...'
                            />
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-4">
                            {
                                roofData.map(image => (
                                    <div className="mr-2" style={{ height: "100px", width: "30%" }}>
                                        <img
                                            style={{
                                                height: "100%", width: "100%", objectFit: "cover",
                                                display: "block", objectPosition: "top", borderRadius: "10px"
                                            }}
                                            src={image.imageUrl}
                                            alt='...'
                                            onClick={() => {
                                                setRoofMain(image.imageUrl);
                                                if (image.id === "10") {
                                                    setRoofOne(roofMain)
                                                }
                                                else if (image.id === "11") {
                                                    setRoofTwo(roofMain)
                                                }
                                                else if (image.id === "12") {
                                                    setRoofThree(roofMain)
                                                }
                                            }}

                                        />
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                    <div className="div-new-width div-text" style={{ width: "48%" }}>
                        <div>
                            <h2>Roofing </h2>
                            <p>
                                When it comes to roofing solutions, durability, aesthetics, and sustainability are paramount. In this regard,
                                aluminum roofing stands tall as a premium choice, offering a myriad of benefits that cater to both practical and aesthetic needs.
                            </p>
                            <p>
                            Aluminum roofing boasts remarkable strength and durability, making it a reliable option for both residential and commercial 
                            properties. Its innate resistance to corrosion ensures longevity, even in the face of harsh weather conditions. Whether 
                            it's scorching sun, heavy rains, or salty sea air, aluminum roofing stands strong, providing lasting protection for your home or business.
                            </p>
                            <p>
                            One of the key advantages of aluminum roofing is its lightweight nature. Unlike traditional roofing materials, 
                            such as tile or slate, aluminum is significantly lighter, reducing structural strain and installation complexities. 
                            This lightweight characteristic not only facilitates
                             easier installation but also contributes to overall energy efficiency, as it minimizes the load on your building's structure.
                            </p>
                            <p>
                            Installation of long span metal roofs is best left to the pros like F.O.Z Engineering Ventures to avoid problems 
                            that comes with bad installation and avoid unnecessary cost.
                            </p>
                            <button className="button" type="button"> Learn More </button>
                        </div>
                       
                    </div>
                </div>
                <div style={{ alignItems: "center", marginTop: "100px" }} className="d-flex flex-row justify-content-around div-block flex-wrap">
                    <div className="div-new-width div-text" style={{ width: "48%" }}>
                    <h2>Curtain Wall, Aluminium Windows and Doors</h2>
                      <p>
                      Curtain wall system is an outer covering of a building in which the outer wall are non-structural, but merely keeps the weather
                       out and occupants in. It can be made of lightweighted materials thereby reducing costs. Types of curtain walls are stick 
                       system - the installation of long pieces curtain wall between floors vertically and horizontally; ladder systems - it has 
                       mullions which can be split and then either clipped or screwed together,
                       utilized system - factory fabrication and assembly of panels and may include factory glazing and spider curtain wall.
                       </p>
                       <p>
                       The curtain wall facade does not carry any dead load weight from the building other than its dead load weight. 
                       When the glass is used as the curtain wall, a great advantage 
                       is that natural light can penetrate deeper witin the building. A curtain is designed to resist air and water infiltration.
                       </p>
                       <button className="button" type="button"> Learn More </button>
                    </div>
                    <div className="div-new-width " style={{ width: "48%" }}>
                    
                        <div>
                            <div className="mr-2" style={{ height: "400px", width: "100%" }}>
                                <img
                                    style={{
                                        height: "100%", width: "100%", objectFit: "cover",
                                        display: "block", objectPosition: "top", borderRadius: "10px"
                                    }}
                                    src={curtainMain}
                                    alt='...'
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-4">
                            {
                                curtainData.map(image => (
                                    <div className="mr-2" style={{ height: "100px", width: "30%" }}>
                                        <img
                                            style={{
                                                height: "100%", width: "100%", objectFit: "cover",
                                                display: "block", objectPosition: "top", borderRadius: "10px"
                                            }}
                                            src={image.imageUrl}
                                            alt='...'
                                            onClick={() => {
                                                setCurtainMain(image.imageUrl);
                                                if (image.id === "13") {
                                                    setCurtainOne(curtainMain)
                                                }
                                                else if (image.id === "14") {
                                                    setCurtainTwo(curtainMain)
                                                }
                                                else if (image.id === "15") {
                                                    setCurtainThree(curtainMain)
                                                }
                                            }}

                                        />
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
                <a href="https://wa.me/+2348075538820?text=Hello"><button style={{color: "#cacae9", fontWeight: "bold", fontSize: "20px", 
                borderRadius: "10px !important", marginTop: "100px"}} className="button" type="button">
                     Book an Appointment </button></a>
            </div>
        </ServiveRap>
    )
}
export default Service