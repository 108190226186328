import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from '@iconify/react';
import Marquee from "react-fast-marquee";
import axios from "axios";
import Loader from "../../Layouts/Custom/overlayProgress";

const BlogRap = styled.div`

.icon-hide {
    display: none;
}
background:#f5f5f5 ;
.btns {
    color: #a97079;
    cursor: pointer;
    font-weight: bold;
}
.btns:hover {
    color: black;
}
.news-div {
    background: white;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}
.hidden {
    display: none;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.flex-container p {
    margin: 0 10px;
    white-space: nowrap; /* Prevent text from wrapping */
}


@media (max-width: 991px)  {
    .blog-block {
        display: block !important;
    }
    .hidden {
        display: block;
    }
    .blog-main {
        width: 100% !important;
    }
    .blog-submain {
        flex-direction: row !important
    }
    .blog-sub {
        width: 100% !important;
    }
    .blog-hide {
        display: none !important;
    }
    .border-removal {
        border-style: none !important;
    }
    .icon-hide {
        display: block !important;
    }
    .flex-container {
        flex-wrap: nowrap !important; /* Prevent wrapping */
        overflow-x: auto !important; /* Enable horizontal scrolling */
        -webkit-overflow-scrolling: touch !important; /* Smooth scrolling on iOS */
    }

    .flex-container p {
        flex: 0 0 auto !important; /* Ensure items don't shrink */
    }
}
@media (max-width: 767px){
    .about-blog {
        display: block !important;
    }
    .about-sub {
        width: 100% !important;
    }
    .news-div {
        flex-direction: column-reverse !important;
    }
    .news-head {
        width: 100% !important;
    }
    .news-img {
        height: 300px !important;
    }
    .newDivBlock {
        flex-direction: column !important;
    }
    .newDivImg {
        width: 100% !important;
    }
}


@media (max-width: 500px) {
    .img2 {
        height: 150px !important;
    }
    .img1 {
        height: 350px !important;
    }
    
}
`

const Blog = () => {

    const [aboutInfo, setAboutInfo] = useState([])
    const [aboutImage, setAboutImage] = useState([])
    const [dream, setDream] = useState([])
    const [vision, setVision] = useState([])
    const [mission, setMission] = useState([])
    const [latest, setLatest] = useState([])
    const [cladding, setCladding] = useState([])
    const [thermal, setThermal] = useState([])
    const [roofing, setRoofing] = useState([])
    const [curtain, setCurtain] = useState([])
    const [mainParag, setMainParag] = useState([])
    const [mainImage, setMainImage] = useState([])
    const [mainHead, setMainHead] = useState("")
    const [subHead1, setSubHead1] = useState("")
    const [subHead2, setSubHead2] = useState("")
    const [paraSub1, setParaSub1] = useState("")
    const [paraSub2, setParaSub2] = useState("")
    const [image1, setImage1] = useState([])
    const [image2, setImage2] = useState([])
    const [newDiv, setNewDiv] = useState([]);
    const [newDivImg, setNewDivImg] = useState([]);
    const [loading, setLoading] = useState(true)
   

    useEffect(() => {
        const fetchNewDiv = async () => {
            try {
                const response = await fetch('https://foz-server.onrender.com/api/get-div-image');
                const data = await response.json();
                setNewDiv(data);
                setNewDivImg(data.divImage)
            } catch (error) {
                console.error('Error fetching new divs:', error);
            }
        };

        fetchNewDiv();
    }, []);

   
    const fetchAbout = async () => {
        try {
            const response = await axios.get('https://foz-server.onrender.com/api/get-about');
            setAboutInfo(response.data.mainPara);
            setDream(response.data.dream)
            setVision(response.data.vision)
            setMission(response.data.mission)
            setAboutImage(response.data.aboutImage)

        } catch (error) {
            console.error('Error retrieving about');
        }
    };
    const fetchLatest = async () => {
        try {
            const response = await axios.get('https://foz-server.onrender.com/api/get-latest');
            setLatest(response.data);

        } catch (error) {
            console.error('Error retrieving latest news');
        }
    };
    const fetchCladding = async () => {
        try {
            const response = await axios.get('https://foz-server.onrender.com/api/get-cladding');
            setCladding(response.data || {});
            setMainHead(response.data.title || "Cladding");
            setMainImage(response.data.claddingImage || {});
            setMainParag(response.data.mainParagraph || []);
            setSubHead1(response.data.subHead1 || "");
            setSubHead2(response.data.subHead2 || "");
            setParaSub1(response.data.paraSub1 || "");
            setParaSub2(response.data.paraSub2 || "");
            setImage1(response.data.claddingImage1 || {});
            setImage2(response.data.claddingImage2 || {});
            setLoading(false)
            

        } catch (error) {
            console.error('Error retrieving cladding messages');
        }
    };
    const fetchThermal = async () => {
        try {
            const response = await axios.get('https://foz-server.onrender.com/api/get-thermal');
            setThermal(response.data);

        } catch (error) {
            console.error('Error retrieving cladding messages');
        }
    };
    const fetchRoofing = async () => {
        try {
            const response = await axios.get('https://foz-server.onrender.com/api/get-roofing');
            setRoofing(response.data);

        } catch (error) {
            console.error('Error retrieving cladding messages');
        }
    };
    const fetchCurtain = async () => {
        try {
            const response = await axios.get('https://foz-server.onrender.com/api/get-curtain');
            setCurtain(response.data);

        } catch (error) {
            console.error('Error retrieving cladding messages');
        }
    };

    useEffect(() => {

        fetchAbout();
        fetchLatest();
        fetchCladding();
        fetchCurtain();
        fetchLatest();
        fetchRoofing();
        fetchThermal()

    }, []);



    if (!aboutInfo) {
        return <div>Loading...</div>;
    }

    const handleCladding = () => {
        setMainHead(cladding.title || "Cladding");
        setMainImage(cladding.claddingImage || {});
        setMainParag(cladding.mainParagraph || []);
        setSubHead1(cladding.subHead1 || "");
        setSubHead2(cladding.subHead2 || "");
        setParaSub1(cladding.paraSub1 || "");
        setParaSub2(cladding.paraSub2 || "");
        setImage1(cladding.claddingImage1 || {});
        setImage2(cladding.claddingImage2 || {});

    }

    const handleThermal = () => {
        setMainHead(thermal.title || "Thermal Insulation");
        setMainImage(thermal.thermalImage || {});
        setMainParag(thermal.mainParagraph || []);
        setSubHead1(thermal.subHead1 || "");
        setSubHead2(thermal.subHead2 || "");
        setParaSub1(thermal.paraSub1 || "");
        setParaSub2(thermal.paraSub2 || "");
        setImage1(thermal.thermalImage1 || {});
        setImage2(thermal.thermalImage2 || {});
    }

    const handleRoofing = () => {

        setMainHead(roofing.title || "Roofing");
        setMainImage(roofing.roofingImage || {});
        setMainParag(roofing.mainParagraph || []);
        setSubHead1(roofing.subHead1 || "");
        setSubHead2(roofing.subHead2 || "");
        setParaSub1(roofing.paraSub1 || "");
        setParaSub2(roofing.paraSub2 || {});
        setImage1(roofing.roofingImage1 || {});
        setImage2(roofing.roofingImage2 || {});
    }
    const handleCurtain = () => {
        setMainHead(curtain.title || "Curtain Wall");
        setMainImage(curtain.curtainImage || {});
        setMainParag(curtain.mainParagraph || []);
        setSubHead1(curtain.subHead1 || "");
        setSubHead2(curtain.subHead2 || "");
        setParaSub1(curtain.paraSub1 || "");
        setParaSub2(curtain.paraSub2 || "");
        setImage1(curtain.curtainImage1 || {});
        setImage2(curtain.curtainImage2 || {});
    }


    return (
        <>
            {loading ? <Loader /> : (

                <BlogRap>
                    <div style={{ padding: "20px 10px" }}>
                        <div className="container">
                            <h1 style={{ fontSize: "50px", marginBottom: "20px" }}>Our Blog</h1>
                            <div className="hidden" style={{ maxWidth: "500px", marginTop: "30px" }}>
                                <h4 style={{ marginBottom: "20px" }}>Services</h4>
                                <div className="d-flex flex-row justify-content-around flex-wrap flex-container" >

                                    <p onClick={handleCladding} className="btns" >Cladding</p>
                                    <p onClick={handleThermal} className="btns" >Thermal Insulation</p>
                                    <p onClick={handleRoofing} className="btns" >Roofing</p>
                                    <p onClick={handleCurtain} className="btns" >Curtain Wall</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between h-100 blog-block">

                                <div style={{ width: "75%" }} className="d-flex justify-content-between blog-main">
                                    <div className="col-8">
                                        <div className="mr-2 img1" style={{ height: "400px", }}>
                                            <img
                                                style={{
                                                    height: "100%", width: "100%", objectFit: "cover",
                                                    display: "block", objectPosition: "top",
                                                }}
                                                src={mainImage.url}
                                                alt='...'
                                            />
                                        </div>
                                        <h3>{mainHead}</h3>
                                        <>
                                            {mainParag ? (
                                                <div>
                                                    {mainParag.map((paragraph) => (
                                                        <div>
                                                            <p>{paragraph}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : ""}
                                        </>
                                    </div>
                                    <div className="col-4">
                                        <div>
                                            <div className="mr-2 img2" style={{ height: "200px", }}>
                                                <img
                                                    style={{
                                                        height: "100%", width: "100%", objectFit: "cover",
                                                        display: "block", objectPosition: "top",
                                                    }}
                                                    src={image1.url}
                                                    alt='...'
                                                />
                                            </div>
                                            <h5>{subHead1}</h5>
                                            <p>{paraSub1}</p>
                                        </div>
                                        <div>
                                            <div className="mr-2 img2" style={{ height: "200px", }}>
                                                <img
                                                    style={{
                                                        height: "100%", width: "100%", objectFit: "cover",
                                                        display: "block", objectPosition: "top",
                                                    }}
                                                    src={image2.url}
                                                    alt='...'
                                                />
                                            </div>
                                            <h5>{subHead2}</h5>
                                            <p>{paraSub2}</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "25%" }} className=" d-flex flex-column justify-content-between blog-sub">
                                    <div className="d-flex flex-column justify-content-between blog-hide" style={{ border: "1px solid #d7cdcd", padding: "20px" }}>
                                        <h4>Services</h4>
                                        <p onClick={handleCladding} className="btns" >Cladding</p>
                                        <p className="btns" onClick={handleThermal}>Thermal Insulation</p>
                                        <p className="btns" onClick={handleRoofing}>Roofing</p>
                                        <p className="btns" onClick={handleCurtain}>Curtain Wall</p>
                                    </div>
                                    <div className="border-removal" style={{ border: "1px solid #d7cdcd", padding: "20px" }}>
                                        <h4 className="blog-hide ">Contacts</h4>
                                        <div className="d-flex flex-column justify-content-between blog-submain blog-hide" >

                                            <a href="https://wa.me/+2348075538820?text=Hello"> <Icon icon="fa6-brands:square-whatsapp" color="black" width="50" height="50" /></a>
                                            <a href="https://www.instagram.com/folatz777?utm_source=qr&igsh=MWNqZDNwYTM5Z3cwbg=="><Icon icon="ri:instagram-fill" color="black" width="50" height="50" /></a>
                                            <a href="https://x.com/FOLARIN777?t=TWj9nrRS8rhL2AC4hoSa1Q&s=09"><Icon icon="mdi:twitter" color="black" width="50" height="50" /></a>
                                            <a href="https://www.facebook.com/Opaleyef10"><Icon icon="ic:baseline-facebook" color="black" width="50" height="50" /></a>
                                            <a href="tell:+2348075538820"><Icon icon="ph:phone-call-fill" color="black" width="50" height="50" /></a>
                                            <a href="mailto:fozengineering@gmail.com"><Icon icon="ic:baseline-email" color="black" width="50" height="50" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-4 icon-hide">
                                <Marquee pauseOnHover={true} speed={40} direction="left">


                                    <a style={{ marginLeft: "80px" }} href="https://wa.me/+2348075538820?text=Hello"> <Icon icon="fa6-brands:square-whatsapp" color="black" width="50" height="50" /></a>
                                    <a style={{ marginLeft: "80px" }} href="https://www.instagram.com/folatz777?utm_source=qr&igsh=MWNqZDNwYTM5Z3cwbg=="><Icon icon="ri:instagram-fill" color="black" width="50" height="50" /></a>
                                    <a style={{ marginLeft: "80px" }} href="https://x.com/FOLARIN777?t=TWj9nrRS8rhL2AC4hoSa1Q&s=09"><Icon icon="mdi:twitter" color="black" width="50" height="50" /></a>
                                    <a style={{ marginLeft: "80px" }} href="https://www.facebook.com/Opaleyef10"><Icon icon="ic:baseline-facebook" color="black" width="50" height="50" /></a>
                                    <a style={{ marginLeft: "80px" }} href="tell:+2348075538820"><Icon icon="ph:phone-call-fill" color="black" width="50" height="50" /></a>
                                    <a style={{ marginLeft: "80px" }} href="mailto:fozengineering@gmail.com"><Icon icon="ic:baseline-email" color="black" width="50" height="50" /></a>

                                </Marquee>
                            </div>

                            <div style={{ marginTop: "80px" }} className="d-flex justify-content-between about-blog">
                                <div style={{ border: "1px solid #d7cdcd", padding: "20px", width: "65%" }} className="about-sub">
                                    <h2 style={{ marginBottom: "20px" }}>About Us</h2>
                                    <div className="mr-2" style={{ height: "400px", marginBottom: "20px" }}>
                                        {aboutImage && aboutImage.url ? (
                                            <img
                                                style={{
                                                    height: "100%", width: "100%", objectFit: "cover",
                                                    display: "block", objectPosition: "top",
                                                }}
                                                src={aboutImage.url}
                                                alt='...'
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {aboutInfo.length > 0 ? (
                                        aboutInfo.map((about, index) => (
                                            <p key={index}>{about}</p>
                                        ))
                                    ) : (
                                        <p>No information available about us.</p>
                                    )}
                                </div>
                                <div style={{ border: "1px solid #d7cdcd", padding: "20px", width: "35%" }} className="about-sub">
                                    <div>
                                        <h4>Our Dream</h4>
                                        {dream.length > 0 ? (
                                            dream.map((dreams, index) => (
                                                <ul key={index}>
                                                    <li>{dreams}</li>
                                                </ul>
                                            ))
                                        ) : (
                                            <p>No dream content available.</p>
                                        )}
                                    </div>
                                    <div>
                                        <h4>Our Vision</h4>
                                        {vision.length > 0 ? (
                                            vision.map((visions, index) => (
                                                <ul key={index}>
                                                    <li>{visions}</li>
                                                </ul>
                                            ))
                                        ) : (
                                            <p>No vision content available.</p>
                                        )}
                                    </div>
                                    <div>
                                        <h4>Our Mission</h4>
                                        {mission.length > 0 ? (
                                            mission.map((missions, index) => (
                                                <ul key={index}>
                                                    <li>{missions}</li>
                                                </ul>
                                            ))
                                        ) : (
                                            <p>No mission content available.</p>
                                        )}
                                    </div>
                                </div>
                            </div>


                            <div className="my-5">
                                {Array.isArray(newDiv) && newDiv.length > 0 ? (
                                    <>
                                        {newDiv.map((newDivs) => (
                                            <div className="my-5" key={newDivs._id} style={{ textAlign: newDivs.divImage?.url ? 'left' : 'center' }}>

                                                <div className="newDivBlock" style={{ display: 'flex', flexDirection: newDivs.divImage?.url ? 'row' : 'column', alignItems: 'center' }}>
                                                    {newDivs.divImage?.url && (
                                                        <div className="mr-2 newDivImg" style={{ marginBottom: "20px", height: "400px", width: "800px" }}>
                                                            <img
                                                                style={{
                                                                    height: "100%", width: "100%", objectFit: "cover",
                                                                    display: "block", objectPosition: "top",
                                                                }}
                                                                src={newDivs.divImage.url}
                                                                alt='...'
                                                            />
                                                        </div>
                                                    )}
                                                    {newDivs.paragraph && (
                                                        <div style={{ marginLeft: newDivs.divImage?.url ? '20px' : '0', marginBottom: "20px" }}>
                                                            {newDivs.title && <h2>{newDivs.title}</h2>}
                                                            {newDivs.paragraph}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>



                            <div>
                                {Array.isArray(latest) && latest.length > 0 ? (
                                    <>
                                        <div style={{ padding: "20px", marginTop: "30px" }}>
                                            <h2 style={{ marginBottom: "20px" }}>What's New</h2>
                                            <p style={{ marginBottom: "20px" }}>Latest News</p>
                                            {latest.map((latests) => (
                                                <div style={{ marginBottom: "20px" }} className="d-flex justify-content-between news-div" key={latests._id}>
                                                    <div className="news-head" style={{ width: "70%" }}>
                                                        <h5>{latests.title}</h5>
                                                        <p>{latests.paragraph}</p>
                                                    </div>
                                                    <div className="news-head" style={{ width: "25%" }}>
                                                        <div className="mr-2 news-img" style={{ marginBottom: "20px", height: "200px" }}>
                                                            <img
                                                                style={{
                                                                    height: "100%", width: "100%", objectFit: "cover",
                                                                    display: "block", objectPosition: "top",
                                                                }}
                                                                src={latests.latestImage.url}
                                                                alt='...'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}

                            </div>


                        </div>
                    </div>
                </BlogRap>
            )}
        </>
    )
}
export default Blog