import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import AdminLoader from "../../Layouts/Custom/adminProgress";



const CreatedDiv = () => {

    const [createdDiv, setCreatedDiv] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchCreated = async () => {
        try {
            const response = await fetch('https://foz-server.onrender.com/api/get-div-image');
            const data = await response.json();
            setCreatedDiv(data);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching new divs:', error);
        }
    };

    useEffect(() => {

        fetchCreated()
    }, [])

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://foz-server.onrender.com/api/delete-section-image/${id}`);
            fetchCreated(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting latest news:', error);
        }
    };

    return (
        <>
            {loading ? <AdminLoader /> : (

                <div>

                    <div>
                        {Array.isArray(createdDiv) && createdDiv.length > 0 ? (
                            <>
                                <div style={{ padding: "20px", marginTop: "30px" }}>
                                    <h2 style={{ marginBottom: "40px" }}> News Posted on Blog</h2>

                                    {createdDiv.map((e) => (
                                        <div key={e._id} style={{ marginBottom: "20px", borderBottom: "2px solid black", padding: "20px" }}>
                                            <div className="news-head">
                                                <h5>{e.title}</h5>
                                                <p>{e.paragraph}</p>
                                            </div>
                                            <button onClick={() => handleDelete(e._id)} style={{ marginTop: "10px", backgroundColor: "red", color: "white", border: "none", padding: "5px 10px", cursor: "pointer" }}>
                                                Delete
                                            </button>
                                        </div>

                                    ))}
                                </div>
                            </>
                        ) : (


                            <div style={{ alignItems: "center", height: "100vh" }} className="d-flex flex-column justify-content-center">

                                <h1 style={{ alignSelf: "center" }} >No New Section Created</h1>

                            </div>
                        )}

                    </div>
                </div>
            )}
        </>
    )
}

export default CreatedDiv