import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import AdminLoader from "../../Layouts/Custom/adminProgress";


const News = () => {

    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchNews = async () => {
        try {
            const response = await axios.get('https://foz-server.onrender.com/api/get-latest');
            setNews(response.data);
            setLoading(false)
        } catch (error) {
            console.error('Error retrieving latest news');
        }
    };

    useEffect(() => {

        fetchNews()
    }, [])

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://foz-server.onrender.com/api/delete-latest/${id}`);
            fetchNews(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting latest news:', error);
        }
    };

    return (
        <>
            {loading ? <AdminLoader /> : (

                <div>

                    <div>
                        {Array.isArray(news) && news.length > 0 ? (
                            <>
                                <div style={{ padding: "20px", marginTop: "30px" }}>
                                    <h2 style={{ marginBottom: "40px" }}> News Posted on Blog</h2>

                                    {news.map((e) => (
                                        <div key={e._id} style={{ marginBottom: "20px", borderBottom: "2px solid black", padding: "20px" }}>
                                            <div className="news-head">
                                                <h5>{e.title}</h5>
                                                <p>{e.paragraph}</p>
                                            </div>
                                            <button onClick={() => handleDelete(e._id)} style={{ marginTop: "10px", backgroundColor: "red", color: "white", border: "none", padding: "5px 10px", cursor: "pointer" }}>
                                                Delete
                                            </button>
                                        </div>

                                    ))}
                                </div>
                            </>
                        ) : (
                            <div style={{ alignItems: "center", height: "100vh" }} className="d-flex flex-column justify-content-center">

                                <h1 style={{ alignSelf: "center" }} >No News Posted</h1>

                            </div>
                        )}

                    </div>
                </div>
            )}
        </>
    )
}

export default News