import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from 'axios';
import { Icon } from '@iconify/react';
import AdminLoader from "../../Layouts/Custom/adminProgress";

const DashRap = styled.div`
  padding:40px;

  input, textarea {
    width: 50%;
    margin: 20px 0px;
  }

  .btn {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    background: white !important;
    font-weight: bold;
  }
  .btn-submit {
    background: blue !important;
    color: white;
  }
  .cancle-btn {
    margin-left: 5px;
     border-style: none;
      background: #f5f5f5; 
      cursor: pointer;
  }
  @media (max-width: 700px) {
    input, textarea {
        width: 100%;
       
      }
}
`;

const BlogAbout = () => {



  const [title, setTitle] = useState("");
  const [mainParas, setMainParas] = useState([]);
  const [dreams, setDreams] = useState([]);
  const [visions, setVisions] = useState([]);
  const [missions, setMissions] = useState([]);
  const [aboutImage, setAboutImage] = useState(null);
  const [loading, setLoading] = useState(true)

  const fetchAbout = async () => {
    try {
      const response = await axios.get('https://foz-server.onrender.com/api/get-about');

      setMainParas(response.data.mainPara);
      setDreams(response.data.dream)
      setVisions(response.data.vision)
      setMissions(response.data.mission)
      setAboutImage(response.data.aboutImage)
      setLoading(false)

    } catch (error) {
      console.error('Error retrieving about');
    }
  };

  useEffect(() => {
    fetchAbout()
  }, [])

  const handleAddInput = (setter) => {
    setter((prev) => [...prev, '']);
  };

  const handleInputChange = (index, event, setter) => {
    setter((prev) => {
      const newInputs = [...prev];
      newInputs[index] = event.target.value;
      return newInputs;
    });
  };

  const handleDeleteInput = (index, setter) => {
    setter((prev) => prev.filter((_, i) => i !== index));
  };

  const handleFileChange = (event) => {
    setAboutImage(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('mainPara', JSON.stringify(mainParas));
    formData.append('dream', JSON.stringify(dreams));
    formData.append('vision', JSON.stringify(visions));
    formData.append('mission', JSON.stringify(missions));
    if (aboutImage) {
      formData.append('aboutImage', aboutImage);
    }

    try {
      const response = await axios.post('https://foz-server.onrender.com/api/about', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      window.location.reload()
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error uploading data:', error);
    }
  };

  return (
    <>
      {loading ? <AdminLoader /> : (

        <DashRap>
          <form onSubmit={handleSubmit} >
            <div >
              <h2 style={{ textAlign: "center" }}>Update About Info</h2>

              <div className="my-4">
                <h4>Main Paragraphs</h4>
                <div>
                  <button className="btn" type="button" onClick={() => handleAddInput(setMainParas)}>Add Paragraph</button>
                  {mainParas.map((input, index) => (
                    <div key={index} className="d-flex">
                      <textarea className="form-control"
                        type="text"
                        value={input}
                        onChange={(event) => handleInputChange(index, event, setMainParas)}
                      />
                      <button className="cancle-btn" type="button" onClick={() => handleDeleteInput(index, setMainParas)}>
                        <Icon icon="uil:times-circle" width="30" height="30" style={{ color: "red" }} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="my-4">
                <h4>Dream</h4>
                <div>
                  <button className="btn" type="button" onClick={() => handleAddInput(setDreams)}>Add Dream</button>
                  {dreams.map((input, index) => (
                    <div key={index} className="d-flex">
                      <textarea className="form-control"
                        type="text"
                        value={input}
                        onChange={(event) => handleInputChange(index, event, setDreams)}
                      />
                      <button className="cancle-btn" type="button" onClick={() => handleDeleteInput(index, setDreams)}>
                        <Icon icon="uil:times-circle" width="30" height="30" style={{ color: "red" }} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="my-4">
                <h4>Vision</h4>
                <div>
                  <button className="btn" type="button" onClick={() => handleAddInput(setVisions)}>Add Vision</button>
                  {visions.map((input, index) => (
                    <div key={index} className="d-flex">
                      <textarea className="form-control"
                        type="text"
                        value={input}
                        onChange={(event) => handleInputChange(index, event, setVisions)}
                      />
                      <button className="cancle-btn" type="button" onClick={() => handleDeleteInput(index, setVisions)}>
                        <Icon icon="uil:times-circle" width="30" height="30" style={{ color: "red" }} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="my-4">
                <h4>Mission</h4>
                <div>
                  <button className="btn" type="button" onClick={() => handleAddInput(setMissions)}>Add Mission</button>
                  {missions.map((input, index) => (
                    <div key={index} className="d-flex">
                      <textarea className="form-control"
                        type="text"
                        value={input}
                        onChange={(event) => handleInputChange(index, event, setMissions)}
                      />
                      <button className="cancle-btn" type="button" onClick={() => handleDeleteInput(index, setMissions)}>
                        <Icon icon="uil:times-circle" width="30" height="30" style={{ color: "red" }} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="my-4">
                <h4>Add About Image</h4>
                <input className="form-control" type="file" onChange={handleFileChange} />
              </div>

              <div  >
                <button className="btn btn-submit" type="submit">Submit</button>
              </div>
            </div>
          </form>
        </DashRap>
      )}
    </>
  );
};

export default BlogAbout;
