import React from "react";
import styled from "styled-components";
import { Icon } from '@iconify/react';

const FooterRap = styled.div` 
background-color: black;

margin-top: 70px;
color: white;
padding: 40px 10px;


a {
    text-decoration: none;
    color: #f1e1e4;
    margin: 10px;
}
.footer1 {
    max-width: 300px;
    margin-bottom: 50px;
}
@media (max-width: 767px)  {
    .footer-block {
        display: block !important;
    }
    .footer-div {
        width: 100% !important;
    }
    .footer-row {
        flex-direction: row !important;
    }
    .location {
        width: 40%;
    }
    .opens {
        width: 45%;
    }
    .navigation {
        border-bottom: 1px solid white;
    }
    .footer-nav {
        flex-direction: row !important;
    }
}
`
const Footer = () => {

    return (
        <FooterRap>

            <div className="container d-flex flex-column g-2">
                <div className="footer1">
                    <h2 style={{ color: "#f1e1e4" }}>Schedule a Consultation with us</h2>
                    <p>Let's work Together</p>
                </div>
                <div className="d-flex justify-content-between footer-block">
                    <div style={{ width: "48%" }} className="d-flex justify-content-between footer-div">
                        <div className="d-flex flex-column">
                            <h4 style={{ color: "#f1e1e4" }}>Contact us</h4>
                            <a className="d-flex align-items-center" href="mailto:fozengineering@gmail.com"><p>
                                <Icon icon="ic:baseline-email" width="20" height="20" color="white" style={{ marginRight: "10px" }} /></p> <p>fozengineering@gmail.com</p></a>
                            <a className="d-flex align-items-center" href="tell:+2348075538820"><p>
                                <Icon icon="eva:phone-call-fill" width="20" height="20" color="white" style={{ marginRight: "10px" }} /></p>
                                <p> +2348075538820</p></a>

                        </div>
                        <div>
                            <h4 style={{ color: "#f1e1e4" }}>Follow Us</h4>
                            <div className="d-flex flex-row flex-wrap my-5">
                                <a href="https://wa.me/+2348075538820?text=Hello"><Icon icon="fa6-brands:square-whatsapp" width="25" height="25" color="white" /></a>
                                <a href="https://x.com/FOLARIN777?t=TWj9nrRS8rhL2AC4hoSa1Q&s=09"><Icon icon="mdi:twitter" width="25" height="25" color="white" /></a>
                                <a href="https://www.facebook.com/Opaleyef10"><Icon icon="ic:baseline-facebook" width="25" height="25" color="white" /></a>
                                <a href="https://www.instagram.com/folatz777?utm_source=qr&igsh=MWNqZDNwYTM5Z3cwbg=="><Icon icon="ri:instagram-fill" width="25" height="25" color="white" /></a>
                                <a href="https://www.linkedin.com/in/folarin-opaleye-9536bb35?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><Icon icon="pajamas:linkedin" width="25" height="25" color="white" /></a>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "48%" }} className="d-flex justify-content-between footer-block footer-div">
                        <div className="d-flex flex-column justify-content-between footer-row">
                            <div className="location">
                                <h4 style={{ color: "#f1e1e4" }}>Location</h4>
                                <p>9B, Aquamarine Estate, Apo, Opposite Apo Mechanic, Abuja.</p>
                            </div>
                            <div className="opens" style={{ marginTop: "35px" }}>
                                <h4 style={{ color: "#f1e1e4" }}>Opens</h4>
                                <p>Monday-Friday</p>
                                <p>8:00am-6:00pm</p>
                            </div>
                        </div>
                        <div>
                            <h4 className="navigation" style={{ color: "#f1e1e4" }}>Navigation</h4>
                            <div className="d-flex flex-column footer-nav">
                               
                                <a href="/about">About Us</a>
                                <a href="/services">Services</a>
                                <a href="/gallery">Gallery</a>
                                <a href="/blog">Blog</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FooterRap>
    )
}

export default Footer