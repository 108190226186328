import React from "react";
import styled from "styled-components";
import DisplayImages from "./DisplayImage";

const GalleryRap = styled.div`
background-color: #1e1a1a;
margin-bottom: -60px;
color: white;
padding: 40px 10px;
.button {
    border-radius: 30px;
    background-color: #212526;
    border: 1px solid #a18e2a;
    color: white;
    padding: 5px 20px;
    margin-top: 30px;
 }
 @media (max-width: 767px) {
    .img-block {
        display: block !important;
    }
 }
`
const Gallery = () => {

    return (
        <GalleryRap>
            <div className="container">
                <div style={{ justifyContent: "flex-end" }} className="d-flex">

                    <h2 style={{ textAlign: "end", maxWidth: "100px", color: "#e5e0e0", marginBottom: '70px' }}>OUR WORKS</h2>
                </div>
                <DisplayImages />
                <div className="d-flex img-block">
                    <div className="d-flex">
                        <div>
                            <div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image10.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image11.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image12.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image13.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image26.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image27.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image28.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image29.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image54.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image55.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image56.jpg"
                                        alt='...'
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className="mr-2" style={{ height: "200px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image14.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image15.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "200px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image16.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image17.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "200px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image30.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image31.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "200px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image32.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image33.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image42.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "200px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image43.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image44.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image61.jpg"
                                        alt='...'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div>
                            <div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image18.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image19.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image20.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image21.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image34.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image35.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image36.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image37.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image46.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image48.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image49.jpg"
                                        alt='...'
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className="mr-2" style={{ height: "200px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image22.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image23.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "200px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image24.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image25.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "200px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image38.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image39.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "200px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image40.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image41.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image50.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "200px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image52.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image53.jpg"
                                        alt='...'
                                    />
                                </div>
                                <div className="mr-2" style={{ height: "250px" }}>
                                    <img
                                        style={{
                                            height: "100%", width: "100%", objectFit: "cover",
                                            display: "block", objectPosition: "top",
                                        }}
                                        src="./images/image60.jpg"
                                        alt='...'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="d-flex justify-content-center">

                    <a href="https://wa.me/+2348075538820?text=Hello"><button style={{
                        color: "#cacae9", fontWeight: "bold", fontSize: "20px", borderRadius: "10px !important", marginTop: "100px"}} 
                        className="button" type="button"> Book an Appointment
                    </button></a>
                </div>
            </div>
        </GalleryRap>
    )
}

export default Gallery