import React from "react"
import { Audio, Puff } from 'react-loader-spinner';


const Loader = () => {
    return (
        <div className="d-flex justify-content-center" style={{ height: "100vh", width: "100vw", background: "black", alignItems: "center" }}>
           <div style={{marginTop: "-100px"}}>
           <Puff 
                visible={true}
                height="150"
                width="150"
                color="blue"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
            </div>
        </div>
    )
}

export default Loader
