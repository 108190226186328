import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import SectionOne from "./SectionOne";

const SectionRap = styled.div`
padding:40px;
input, textarea {
    width: 50%;
}
.btn {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    background: white !important;
    font-weight: bold;
  }
  .btn-submit {
    background: blue !important;
    color: white;
  }
  .div-box {
    padding: 15px; 
    margin: 10px;
    background: white;
    color: black;
    font-size: 20px;
    font-weight: bold;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    // width: 48%;
    display: flex;
    text-align: center;
   border-style: none;
    border-radius: 20px;
    cursor: pointer;
}
.div-box:hover {
    background: black;
    color: white;
}

@media (max-width: 700px) {
    input, textarea {
        width: 100%;
       
      }
}
`

const NewSection = () => {
  
    const [title, setTitle] = useState("");
    const [paragraph, setParagraph] = useState("");
    const [divImage, setDivImage] = useState(null);


    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };


    const handleParagraphChange = (event) => {
        setParagraph(event.target.value);
    };

    const handleImageChange = (event) => {
        setDivImage(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("title", title);
        formData.append("paragraph", paragraph);
       
        if (divImage) {
            formData.append("divImage", divImage);
        }

        try {
            const response = await axios.post("https://foz-server.onrender.com/api/div-image", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("Response:", response.data);
        } catch (error) {
            console.error("Error uploading data:", error);
        }
    };




 
    return (
        <SectionRap>
            <div >
                <h2 style={{ textAlign: "center", margin: "20px" }}>Create New Section On Your Blog</h2>
                <div>
                        <form style={{ marginTop: "60px" }} onSubmit={handleSubmit}>
                            <div>
                                <h5>Section Title</h5>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={title}
                                    onChange={handleTitleChange}
                                />
                            </div>
                            <div>
                                <h5>Section Paragraph</h5>
                                <textarea
                                    className="form-control"
                                    value={paragraph}
                                    onChange={handleParagraphChange}
                                />
                            </div>
                            <div>
                                <h5>Section Image</h5>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={handleImageChange}
                                />
                            </div>
                            <div style={{ textAlign: "center", marginTop: "20px" }}>
                                <button className="btn btn-submit" type="submit">Submit</button>
                            </div>
                        </form>
                  
                </div>
               
            </div>
        </SectionRap>
    )
}
export default NewSection