import { useRoutes } from "react-router-dom";
import General from "../Layouts/General/generalLayout";
import Home from "../Component/Home";
import Service from "../Component/HomeLayouts/Service";
import Gallery from "../Component/HomeLayouts/Gallery";
import About from "../Component/About";
import Blog from "../Component/HomeLayouts/Blog";
import PrivateRoutes from "../Component/Admin/adminPrivate";
import AdminLogin from "../Component/Admin/adminLogin";
import Dashboard from "../Component/Admin/Dashbord";
import BlogAbout from "../Component/Admin/blogAbout";
import Latest from "../Component/Admin/Latest";
import AllSection from "../Component/Admin/AllSection";
import NewSection from "../Component/Admin/NewSection";
import News from "../Component/Admin/News";
import CreatedDiv from "../Component/Admin/CreatedDiv";
import UploadImage from "../Component/Admin/ImageUpload";
import DisplayImages from "../Component/HomeLayouts/DisplayImage";
import UploadedImage from "../Component/Admin/UploadedImage";
import Loader from "../Layouts/Custom/overlayProgress";
import Cladding from "../Component/Admin/Cladding";
import Roofing from "../Component/Admin/Roofing";
import Curtain from "../Component/Admin/Curtain";
import Thermal from "../Component/Admin/Thermal";
// import About from "../Component/About";

export default function Routes(){
    return(
        useRoutes(
            [
                {
                    path: "/",
                    element: <General/>,
                    children: [
                        {path: "/" , element: <Home />},
                        {path: "/services" , element: <Service />},
                        {path: "/gallery", element: <Gallery />},
                        {path: "/about", element: <About />},
                        {path: "/blog", element: <Blog />},
                        {path: "/display-image" , element: <DisplayImages/>},
                        {path: "/load" , element: <Loader/>},

                    ]
                },

                {
                    path: "/admin",
                    element: <PrivateRoutes/>,
                    children: [
                        {path: "/admin" , element: <Dashboard/>},
                        {path: "/admin/update-blogAbout" , element: <BlogAbout/>},
                        {path: "/admin/add-LatestNews" , element: <Latest/>},
                        {path: "/admin/update-section" , element: <AllSection/>},
                        {path: "/admin/create" , element: <NewSection/>},
                        {path: "/admin/news" , element: <News/>},
                        {path: "/admin/created-section" , element: <CreatedDiv/>},
                        {path: "/admin/upload-image" , element: <UploadImage/>},
                        {path: "/admin/image-uploaded" , element: <UploadedImage/>},
                        {path: "/admin/cladding" , element: <Cladding/>},
                        {path: "/admin/thermal" , element: <Thermal/>},
                        {path: "/admin/roofing" , element: <Roofing/>},
                        {path: "/admin/curtain" , element: <Curtain/>},
                    ]
                },


                {path: "/adminLogin", element: <AdminLogin />}
                
            ]
        )
    )
}