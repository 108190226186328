import React from "react"
import { Audio, Puff, Oval } from 'react-loader-spinner';
import styled from "styled-components";

const Wrap = styled.div`
@media (max-width: 991px) {
    .d-flex: width: 100vw !important;
}
`


const AdminLoader = () => {
    return (
        <div className="d-flex justify-content-center" style={{ height: "100vh", width: "80vw", background: "black", alignItems: "center" }}>
            <div style={{ marginTop: "-100px" }}>
                <Oval
                    visible={true}
                    height="150"
                    width="150"
                    color="blue"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        </div>
    )
}

export default AdminLoader
