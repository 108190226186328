import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from 'axios';
import { Icon } from '@iconify/react';

const DashRap = styled.div`
  padding:40px;

  input, textarea {
    width: 50%;
    margin: 20px 0px;
  }

  .btn {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    background: white !important;
    font-weight: bold;
  }
  .btn-submit {
    background: blue !important;
    color: white;
  }
  .cancle-btn {
    margin-left: 5px;
     border-style: none;
      background: #f5f5f5; 
      cursor: pointer;
  }
  @media (max-width: 700px) {
    input, textarea {
        width: 100%;
       
      }
}
`;

const Roofing = () => {
    const [title, setTitle] = useState("");
    const [mainParagraph, setMainParagraph] = useState([]);
    const [subHead1, setSubHead1] = useState("");
    const [subHead2, setSubHead2] = useState("");
    const [paraSub1, setParaSub1] = useState("");
    const [paraSub2, setParaSub2] = useState("");
    const [roofingImage, setRoofingImage] = useState(null);
    const [roofingImage1, setRoofingImage1] = useState(null);
    const [roofingImage2, setRoofingImage2] = useState(null);

    const fetchRoofing = async () => {
        try {
            const response = await axios.get('https://foz-server.onrender.com/api/get-roofing');
            setTitle(response.data.title)
           
            setMainParagraph(response.data.mainParagraph)
            setSubHead1(response.data.subHead1)
            setSubHead2(response.data.subHead2)
            setParaSub1(response.data.paraSub1)
            setParaSub2(response.data.paraSub2)
           
        } catch (error) {
            console.error('Error retrieving cladding messages');
        }
    };

    useEffect(() => {
        fetchRoofing()
    }, [])

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleSub1 = (event) => {
        setSubHead1(event.target.value);
    };
    const handleSub2 = (event) => {
        setSubHead2(event.target.value);
    };
    const handlePara1 = (event) => {
        setParaSub1(event.target.value);
    };
    const handlePara2 = (event) => {
        setParaSub2(event.target.value);
    };


    const handleAddInput = (setter) => {
        setter((prev) => [...prev, '']);
    };

    const handleInputChange = (index, event, setter) => {
        setter((prev) => {
            const newInputs = [...prev];
            newInputs[index] = event.target.value;
            return newInputs;
        });
    };

    const handleDeleteInput = (index, setter) => {
        setter((prev) => prev.filter((_, i) => i !== index));
    };

    const handleFileChange = (event) => {
        setRoofingImage(event.target.files[0]);
    };
    const handleFileChange1 = (event) => {
        setRoofingImage1(event.target.files[0]);
    };
    const handleFileChange2 = (event) => {
        setRoofingImage2(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('mainParagraph', JSON.stringify(mainParagraph));
        formData.append('subHead1', subHead1);
        formData.append('subHead2', subHead2);
        formData.append('paraSub1', paraSub1);
        formData.append('paraSub2', paraSub2);
        if (roofingImage) {
            formData.append('roofingImage', roofingImage);
        }
        if (roofingImage1) {
            formData.append('roofingImage1', roofingImage1);
        }
        if (roofingImage2) {
            formData.append('roofingImage2', roofingImage2);
        }

        try {
            const response = await axios.post('https://foz-server.onrender.com/api/post-roofing', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };

    return (
        <DashRap>
            <form onSubmit={handleSubmit} >
                <div >
                    <h2 style={{ textAlign: "center" }}>Update Roofing Information</h2>
                    <div>
                        <div>
                            <h5>Main Heading</h5>
                            <input
                                type="text"
                                className="form-control"
                                value={title}
                                onChange={handleTitleChange}
                            />
                        </div>
                        <div className="my-4">
                            <h5>First Section Paragraphs</h5>
                            <p>You can have more than one paragraph</p>
                            <div>
                                <button className="btn" type="button" onClick={() => handleAddInput(setMainParagraph)}>Add Paragraph</button>
                                {mainParagraph.map((input, index) => (
                                    <div key={index} className="d-flex my-4">
                                        <textarea className="form-control"
                                            type="text"
                                            value={input}
                                            onChange={(event) => handleInputChange(index, event, setMainParagraph)}
                                        />
                                        <button className="cancle-btn" type="button" onClick={() => handleDeleteInput(index, setMainParagraph)}>
                                            <Icon icon="uil:times-circle" width="30" height="30" style={{ color: "red" }} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="my-4">
                            <h5>Add Main Image</h5>
                            <input className="form-control" type="file" onChange={handleFileChange} />
                        </div>
                    </div>
                    <div>
                        <div>
                             <h5>Section Two First Heading</h5>
                             <input
                                type="text"
                                className="form-control"
                                value={subHead1}
                                onChange={handleSub1}
                            />
                        </div>
                        <div>
                             <h5>Section Two First Paragraph</h5>
                             <textarea
                                type="text"
                                className="form-control"
                                value={paraSub1}
                                onChange={handlePara1}
                            />
                        </div>
                        <div className="my-4">
                            <h5>Add Second Image</h5>
                            <input className="form-control" type="file" onChange={handleFileChange1} />
                        </div>
                    </div>
                    <div>
                        <div>
                             <h5>Section Two Second Heading</h5>
                             <input
                                type="text"
                                className="form-control"
                                value={subHead2}
                                onChange={handleSub2}
                            />
                        </div>
                        <div>
                             <h5>Section Two Second Paragraph</h5>
                             <textarea
                                type="text"
                                className="form-control"
                                value={paraSub2}
                                onChange={handlePara2}
                            />
                        </div>
                        <div className="my-4">
                            <h5>Add Third Image</h5>
                            <input className="form-control" type="file" onChange={handleFileChange2} />
                        </div>
                    </div>

                    <div  >
                        <button className="btn btn-submit" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </DashRap>
    );
};

export default Roofing;
