import React from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';



const DashboardRap = styled.div`
padding: 40px;

.div-box {
    padding: 15px; 
    margin: 10px;
    background: white;
    color: black;
    font-size: 20px;
    font-weight: bold;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 48%;
    display: flex;
    text-align: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
}

@media (max-width: 700px) {
    .div-box {
        width: 60% !important;
       
    }
}

@media (max-width: 550px) {
    .div-box {
        width: 80% !important;
    }
}
@media (max-width: 390px) {
    .div-box {
        width: 100% !important;
    }
}
`


const Dashboard = () => {
    const navigate = useNavigate()


    const handlePic = () => {
        navigate("/admin/upload-image")
    }
    const handleSection = () => {
        navigate("/admin/update-section")
    }
    
   const handleCreate = () => {
    navigate("/admin/create")
   }
    const handleAbout = () => {
        navigate("/admin/update-blogAbout")
    }
    const handleLatest = () => {
        navigate("/admin/add-LatestNews")
    }

    return (
        <DashboardRap>
            <div>
            <div onClick={handlePic} className="div-box">
                    <p>Add Picture to Gallery</p>
                </div>
            <div onClick={handleSection} className="div-box">
                    <p>Update First Section</p>
                </div>
                
               
                <div onClick={handleAbout} className="div-box">
                    <p>Update Blog About Us</p>
                </div>
                <div onClick={handleLatest} className="div-box">
                    <p>Update Latest News</p>
                </div>
                <div onClick={handleCreate} className="div-box">
                    <p>Create New Section</p>
                </div>
            </div>
        </DashboardRap>
    )
}

export default Dashboard