import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";


const SectionRap = styled.div`
padding:40px;
input, textarea {
    width: 50%;
}
.btn {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    background: white !important;
    font-weight: bold;
  }
  .btn-submit {
    background: blue !important;
    color: white;
  }
  .div-box {
    padding: 15px; 
    margin: 10px;
    background: white;
    color: black;
    font-size: 20px;
    font-weight: bold;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    // width: 48%;
    display: flex;
    text-align: center;
   border-style: none;
    border-radius: 20px;
    cursor: pointer;
}
.div-box:hover {
    background: black;
    color: white;
}
`

const SectionOne = () => {
  
    const [head, setHead] = useState("");
    const [secPara, setSecPra] = useState("");

    const handleHeadChange = (event) => {
        setHead(event.target.value);
    };
    const handleParaChange = (event) => {
        setSecPra(event.target.value);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("head", head);
        formData.append("secPara", secPara);

        try {
            const response = await axios.post("https://foz-server.onrender.com/api/new-div", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("Response:", response.data);
        } catch (error) {
            console.error("Error uploading data:", error);
        }
    };



    return (
        <SectionRap>
            <div >

                
                    
                        <form style={{ marginTop: "10px" }} onSubmit={handleSubmit}>
                            <div>
                                <h5>Section Title</h5>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={head}
                                    onChange={handleHeadChange}
                                />
                            </div>
                            <div>
                                <h5>Section Paragraph</h5>
                                <textarea
                                    className="form-control"
                                    value={secPara}
                                    onChange={handleParaChange}
                                />
                            </div>
                            <div style={{ textAlign: "center", marginTop: "20px" }}>
                                <button className="btn btn-submit" type="submit">Submit</button>
                            </div>
                        </form>
                   
                
            </div>
        </SectionRap>
    )
}
export default SectionOne