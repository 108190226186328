import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const DisplayRap = styled.div`
  .image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Default: 4 columns */
    gap: 10px;
    padding: 10px;
  }
  .image-item {
    height: 250px;
  }

  @media (max-width: 1200px) {
    .image-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 columns on screens up to 1200px */
    }
  }

  @media (max-width: 992px) {
    .image-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on screens up to 992px */
    }
  }

  @media (max-width: 768px) {
    .image-grid {
      grid-template-columns: repeat(1, 1fr); /* 1 column on screens up to 768px */
    }
  }

  .image-item img {
    width: 100%;
    object-fit: cover;
    height: 100%; /* Ensure image fills container */
  }

  .no-images {
    text-align: center;
    font-size: 18px;
    color: #666;
    padding: 20px;
  }
`;

const DisplayImages = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://foz-server.onrender.com/api/get-images');
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <DisplayRap>
      {images.length > 0 ? (
        <div className="image-grid container">
          {images.map((image, index) => (
            <div className="image-item" key={image._id}>
              <img
               style={{
                height: "100%", width: "100%", objectFit: "cover",
                display: "block", objectPosition: "top",
            }}
              src={image.url} alt="Uploaded" />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </DisplayRap>
  );
};

export default DisplayImages;
