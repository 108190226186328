import { Outlet } from "react-router-dom";
import Nav from "../../Component/Navbar";
import Footer from "../../Component/Footer";

export default function General(){
    return(
        <>
        <Nav/>
       
        <Outlet/>
       
       <Footer />
        </>
        
    )
}